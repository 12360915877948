import React, { useState } from "react";
import { navigate } from "gatsby-link";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function Contact() {
  const [state, setState] = useState({});
  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <Helmet>
        <title>Hyloq | Contact</title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://hyloq.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Hyloq is the first true-scope geofencing advertising platform, Geofenced ads matched with unparalled data feedback for effectkive, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Hyloq is the first true-scope geofencing advertising platform. Geofenced ads matched with unparalled data feedback for effective, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://hyloq.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-2 lg:py-12">
          <div className="lg:pr-8">
            <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                Let's work together
              </h2>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                We’d love to hear from you! Send us a message using the form
                below.
              </p>
              <p className="text-xs text-red-500 py-4">
                *Denotes required field
              </p>

              <form
                name="contact-main"
                method="post"
                action="/thank-you/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact-main" />
                <p hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>
                <div>
                  <label
                    for="firstName"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    First name*
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    onChange={handleChange}
                    autocomplete="given-name"
                    className="py-2 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label
                    for="lastName"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Last name*
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    onChange={handleChange}
                    autocomplete="family-name"
                    className="py-2 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Email*
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    onChange={handleChange}
                    className="py-2 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  />
                </div>

                <div>
                  <label
                    for="company"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Company*
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    onChange={handleChange}
                    className="py-2 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label
                    for="title"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Title*
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    onChange={handleChange}
                    className="py-2 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label
                    for="phone"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    onChange={handleChange}
                    className="py-2 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                    aria-describedby="phone-optional"
                  />
                </div>

                <div class="sm:col-span-2">
                  <div class="flex justify-between">
                    <label
                      for="message"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >
                      How can we help you?
                    </label>
                    <span class="text-sm leading-5 text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      onChange={handleChange}
                      class="form-textarea block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md p-4"
                      aria-describedby="message-max"
                    ></textarea>
                  </div>
                </div>
                <div className="text-left sm:col-span-2">
                  {SubmitDisabled === true ? (
                    <button
                      type="submit"
                      class="mt-2 w-full inline-flex items-center justify-center px-20 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-800 hover:bg-blue-700 focus:outline-none sm:w-auto"
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      class="mt-2 w-full inline-flex items-center justify-center px-20 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-800 hover:bg-blue-700 focus:outline-none sm:w-auto"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
